body {
	margin: 0;
	font-family: Arial, sans-serif !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: black;
}

button:focus {
	outline: 0;
}

button {
	cursor: pointer;
}

button:hover {
	background-color: whitesmoke;
}
