.wrapper {
	text-align: center;
}


.copyright a {
	color: black;
}

.loadWrapper {
	height: 100vh;
	width: 100vw;
	padding: 0;
	text-align: center;
}


.skipButton {
	border: 8px solid black;
	border-radius: 10px;
	background-color: white;
	color: black;
	padding: 30px 80px;
	text-align: center;
	text-decoration: none;
	font-size: 1.5rem;
	font-weight: bold;
	margin: auto 0 10px auto;
}

.vs {
	height: 100%;
	width: 100px;
	margin: 0;
	text-align: center;
	float: left;
}

.vsCentre {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 80%;
	height: 300px;
	font-weight: bold;
	font-size: 32px;
}


button {
	border: none;
}

.rateScreenTitle {
	font-size: 40pt;
	margin: 20px;
}

/**
Matches md cols
 */
@media (min-width: 768px) and (max-width: 1200px) {
	.vs {
		width: 100%;
	}

	.rateScreenTitle {
		margin: 10px;
	}

	.vsCentre {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 0%;
		height: 20px;
		font-weight: bold;
		font-size: 32px;
	}
}

/**
Phones
 */
@media (max-width: 576px) {

	.rateScreenTitle {
		font-size: 2.6rem;
		margin: 10px;
	}


	.vs {
		width: 100%;
		padding-bottom: 20px;
	}

	.vsCentre {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 0%;
		height: 20px;
		font-weight: bold;
		font-size: 32px;
	}

	.skipButton {
		padding: 15px 40px;
		font-size: 12pt;
	}
}
