.App {
	text-align: center;
}

.banner {
	text-align: center;
	background-color: red;
	font-size: 1rem;
	padding: 15px 0;
	color: white;
	font-weight: bold;
}

.copyright {
	padding: 15px 0;
	font-size: 0.8rem;
}
